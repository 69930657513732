import React from 'react';

 
const Signin = React.lazy(() => import('./Components/Authentication/SignIn/SignIn'));
const ForgotPassword = React.lazy(() => import('./Components/Authentication/SignIn/ForgotPassword'));
const PasswordReset = React.lazy(() => import('./Components/Authentication/SignIn/PasswordReset'));

const route = [ 
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/forgot-assword', exact: true, name: 'ForgotPassword', component: ForgotPassword },
    { path: '/auth/reset-password/:resetKey', exact: true, name: 'PasswordReset', component: PasswordReset }
];

export default route;